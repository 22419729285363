import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import { baseEnvironmentConfig } from "@/api/constants";
import DynamicRenderer from "@/components/DynamicRenderer";
import Layout from "@/components/Layout";
import { ACDL_GLOBAL_TRACKING } from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/global-tracking";
import { CONTENT_TYPES } from "@/constants";
import BrazeContext from "@/context/BrazeContext";
import { DigitalDataLayerContext } from "@/context/DigitalDataLayer";
import { UserContext } from "@/context/User";
import useWebView from "@/hooks/useWebView";
import { pageRouterAuth } from "@/server/auth0";
import { getCmsEntries } from "@/server/lib/contentful";

const HomePage = ({
  metaData,
  modules = [],
  navigation,
  notificationBanner,
  showFooter = true,
  showHeader = true,
  slug,
  useSimpleHeader = false,
}) => {
  const brazeContext = useContext(BrazeContext);
  const { setNewDataLayer } = useContext(DigitalDataLayerContext);
  const userContext = useContext(UserContext);
  const [acdlPageLoadFlag, setAcdlPageLoadFlag] = useState(false);
  const isWebView = useWebView();

  // Display if there is any new or unviewed content cards from braze when user lands on home page
  useEffect(() => {
    if (!isWebView && brazeContext.isInitialized) {
      const braze = brazeContext.instance;

      //The below code refresh content cards every 10 seconds to get unviewed cards
      const refreshCards = setInterval(() => {
        braze.requestContentCardsRefresh();
      }, 10000);

      braze.logCustomEvent("WEB_HOME_PAGE_CONTENT_CARD");

      let isFirstRefreshWithCards = true;

      braze.subscribeToContentCardsUpdates((update) => {
        if (isFirstRefreshWithCards && update.getUnviewedCardCount() > 0) {
          braze.showContentCards(document.querySelector("#braze-feed"));
          isFirstRefreshWithCards = false;
          clearInterval(refreshCards);
        }
      });

      //It clears the refreshCards interval on unmounting this page
      return () => clearInterval(refreshCards);
    }
  }, [brazeContext.instance, brazeContext.isInitialized, isWebView]);

  // ACDL data layer integration
  useEffect(() => {
    if (userContext && userContext.initialized && !acdlPageLoadFlag) {
      setAcdlPageLoadFlag(true);
      const { getDataLayerInfo } = userContext;
      const ACDLInfo = {
        ...ACDL_GLOBAL_TRACKING.homePage(
          metaData?.fields?.title?.toLowerCase()
        ),
        user: getDataLayerInfo(),
      };

      setNewDataLayer(ACDLInfo);
    }
  }, [acdlPageLoadFlag, metaData?.fields?.title, setNewDataLayer, userContext]);

  return (
    <Layout
      dataLayer={{}}
      hideFooter={!showFooter}
      metaData={metaData?.fields}
      modules={modules}
      navigation={showHeader ? navigation : null}
      notificationBanner={notificationBanner}
      page={baseEnvironmentConfig.pageNames.home}
      pageSlug={slug}
      useSimpleHeader={useSimpleHeader}
    >
      {modules?.map((module, index) => (
        <DynamicRenderer
          {...module?.fields}
          data={module?.fields}
          fileName={module?.sys?.contentType?.sys?.id}
          id={module?.sys?.id}
          key={`${module?.sys?.id}-${index + 1}`}
        />
      ))}

      <div id="braze-feed"></div>
    </Layout>
  );
};

HomePage.propTypes = {
  metaData: PropTypes.object,
  modules: PropTypes.array.isRequired,
  navigation: PropTypes.object,
  notificationBanner: PropTypes.object,
  showFooter: PropTypes.bool,
  showHeader: PropTypes.bool,
  slug: PropTypes.string,
  useSimpleHeader: PropTypes.bool,
};

export const getServerSideProps = async ({ req, res }) => {
  const session = await pageRouterAuth.getSession(req, res);

  if (session?.user) {
    return {
      redirect: {
        destination: "/memberbenefits",
        permanent: false,
      },
    };
  }

  let pageData;

  try {
    pageData = await getCmsEntries({
      contentType: CONTENT_TYPES.PAGE_TEMPLATE,
      filters: {
        "fields.slug": "/",
      },
    });
  } catch (error) {
    console.error("error");
  }

  if (!pageData?.fields) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      ...pageData.fields,
      slug: "home",
    },
  };
};

export default HomePage;
